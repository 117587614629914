// ==== Component ========================================================
// Input Field
// =======================================================================

import React, { Component } from "react";

// styles
import styles from "./InputField.module.scss";

export class InputField extends Component {
  render() {
    const { label, type, name, id, placeholder, inline } = this.props;

    return (
      <div className={`${styles.Field} ${inline ? styles.FieldInline : ""}`}>
        <label className={styles.Label} htmlFor={id}>
          {label}
        </label>
        <input className={styles.Input} type={type} name={name} id={id} />
      </div>
    );
  }
}

export default InputField;
