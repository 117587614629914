// ==== Component ========================================================
// Header
// =======================================================================

import React, { Component } from "react";
import { Link } from "react-router-dom";

// Modules
import Brand from "../Brand/index";

// Styles
import styles from "./Header.module.scss";

export class Header extends Component {
  render() {
    return (
      <div className={styles.Header}>
        <Brand />
        <nav className={styles.Nav}>
          <Link className={styles.NavLink} to="/dashboard">
            Dashboard
          </Link>
          <Link className={styles.NavLink} to="/projects">
            Projects
          </Link>
          <Link className={styles.NavLink} to="/">
            Logout
          </Link>
        </nav>
      </div>
    );
  }
}

export default Header;
