// ==== Component ========================================================
// Hero
// =======================================================================

import React, { Component } from "react";

// Modules

// Styles
import styles from "./Hero.module.scss" ;

export class Hero extends Component {
  render() {

    const { title } = this.props;
    return (
      <div className={styles.Hero}>
        <h1 className={styles.Title}>{title}</h1>
      </div>
    );
  }
}

export default Hero;
