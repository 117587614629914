// ==== Component ========================================================
// Button
// =======================================================================

import React, { Component } from "react";

// styles
import styles from "./Button.module.scss";

export class Button extends Component {
  render() {
    const { label, type, id } = this.props;
    return (
      <button type={type} id={id} className={styles.Button}>
        {label}
      </button>
    );
  }
}

export default Button;
