// ==== Component ========================================================
// Projects
// =======================================================================

import React, { Component } from "react";
import { Link } from "react-router-dom";

// Modules
import Header from "../Header/index";
import Hero from "../Hero/index";
import Filter from "../Filter/index";
import { Currency, Number } from "../NumberFormat/index";

// Styles
import "../scss/modules/table.scss";
import styles from "./Projects.module.scss";

export class Projects extends Component {
  projects = [
    {
      id: "864b3a51-1616-40a0-baf7-a683dba04935",
      name: "ABC Company",
      payment_terms: "",
      invoicing_terms: "",
      deposit_on_hand: 12234234.03,
      forecasted_work: 12234234.03,
      outstanding_invoice: 12234234.03,
      total_revenue_to_date: 12234234.03,
      billable_rate: 12234234.03,
      realized_hourly_rate: 12234234.03,
      hours_worked: 123,
      weeks_worked: 123,
      hours_resourced_to_date: 123,
      hours_delta: 123,
      hours_planned: 123,
      hours_remaining: 123,
      date_stared: "2019-11-13",
      date_end: "2019-11-13"
    },
    {
      id: "864b3a51-1616-40a0-baf7-a683dba04936",
      name: "ABC Company",
      payment_terms: "",
      invoicing_terms: "",
      deposit_on_hand: 12234234.03,
      forecasted_work: 12234234.03,
      outstanding_invoice: 12234234.03,
      total_revenue_to_date: 12234234.03,
      billable_rate: 12234234.03,
      realized_hourly_rate: 12234234.03,
      hours_worked: 123,
      weeks_worked: 123,
      hours_resourced_to_date: 123,
      hours_delta: 123,
      hours_planned: 123,
      hours_remaining: 123,
      date_stared: "2019-11-13",
      date_end: "2019-11-13"
    },
    {
      id: "864b3a51-1616-40a0-baf7-a683dba04937",
      name: "ABC Company",
      payment_terms: "",
      invoicing_terms: "",
      deposit_on_hand: 12234234.03,
      forecasted_work: 12234234.03,
      outstanding_invoice: 12234234.03,
      total_revenue_to_date: 12234234.03,
      billable_rate: 12234234.03,
      realized_hourly_rate: 12234234.03,
      hours_worked: 123,
      weeks_worked: 123,
      hours_resourced_to_date: 123,
      hours_delta: 123,
      hours_planned: 123,
      hours_remaining: 123,
      date_stared: "2019-11-13",
      date_end: "2019-11-13"
    }
  ];
  render() {
    return (
      <div className={`Page ${styles.Projects}`}>
        <Header />
        <Hero title="Projects" />
        <Filter />
        <main className="main">
          <table className="table" cellSpacing="0" cellPadding="0">
            <thead className="table__header">
              <tr className="table__row">
                <th className="table__heading">Project</th>
                <th className="table__heading">Payment Terms</th>
                <th className="table__heading">Invoicing Terms</th>
                <th className="table__heading">Deposit on Hand </th>
                <th className="table__heading">Forcasted Work</th>
                <th className="table__heading">Outstanding Invoice</th>
                <th className="table__heading">Total Revenue Date</th>
                <th className="table__heading">Billable Rate</th>
                <th className="table__heading">RHR (Realized Hourly Rate)</th>
                <th className="table__heading">Hours Worked</th>
                <th className="table__heading">Weeks Worked</th>
                <th className="table__heading">Hours Resourced To Date</th>
                <th className="table__heading">Hours Delta</th>
                <th className="table__heading">Hours Planned (Forecast)</th>
                <th className="table__heading">
                  Hours Remainingin Contract (Harvest)
                </th>
              </tr>
            </thead>
            <tbody>
              {this.projects.map((project, key) => {
                const {
                  id,
                  name,
                  payment_terms,
                  invoicing_terms,
                  deposit_on_hand,
                  forecasted_work,
                  outstanding_invoice,
                  total_revenue_to_date,
                  billable_rate,
                  realized_hourly_rate,
                  hours_worked,
                  weeks_worked,
                  hours_resourced_to_date,
                  hours_delta,
                  hours_planned,
                  hours_remaining
                } = project;
                return (
                  <tr className="table__row" key={key} id={id}>
                    <td className="table__data">
                      <Link to={`/projects/${id}/invoices`}>{name}</Link>
                    </td>
                    <td className="table__data">{payment_terms || `-`}</td>
                    <td className="table__data">{invoicing_terms || `-`}</td>
                    <td className="table__data table__data--has-number">
                      <Currency number={deposit_on_hand} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Currency number={forecasted_work} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Currency number={outstanding_invoice} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Currency number={total_revenue_to_date} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Currency number={billable_rate} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Currency number={realized_hourly_rate} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Number number={hours_worked} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Number number={weeks_worked} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Number number={hours_resourced_to_date} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Number number={hours_delta} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Number number={hours_planned} />
                    </td>
                    <td className="table__data table__data--has-number">
                      <Number number={hours_remaining} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </main>
      </div>
    );
  }
}

export default Projects;
