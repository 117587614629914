// ==== Component ========================================================
// Invoices
// =======================================================================

import React, { Component } from "react";

// Modules
import Header from "../Header/index";
import Hero from "../Hero/index";
import Revenue from "../Revenue/index";
import { Currency } from "../NumberFormat/index";

// Styles

export class Invoices extends Component {
  project = {
    name: "Project Name",
    invoices: [
      {
        invoice_id: "864b3a51-1616-40a0-baf7-a683dba04936",
        subject: "Web development",
        issue_date: "2019-11-20",
        payment_term: "string",
        period_start: "2019-11-20",
        period_end: "2019-11-20",
        amount: 12234234.03,
        state: true,
        paid_date: "2019-11-20"
      },
      {
        invoice_id: "864b3a51-1616-40a0-baf7-a683dba04923",
        subject: "Web development",
        issue_date: "2019-11-20",
        payment_term: "string",
        period_start: "2019-11-20",
        period_end: "2019-11-20",
        amount: 12234234.03,
        state: true,
        paid_date: "2019-11-20"
      },
      {
        invoice_id: "864b3a51-1616-40a0-baf7-a683dba04423",
        subject: "Web development",
        issue_date: "2019-11-20",
        payment_term: "string",
        period_start: "2019-11-20",
        period_end: "2019-11-20",
        amount: 12234234.03,
        state: true,
        paid_date: "2019-11-20"
      }
    ]
  };

  render() {
    return (
      <div className={`Page`}>
        <Header />
        <Hero title={`Invoices for ${this.project.name}`} />
        <main className="main">
          
          <table className="table" cellSpacing="0" cellPadding="0">
            <thead className="table__header">
              <tr className="table__row">
                <th className="table__heading">Invoice Number</th>
                <th className="table__heading">Subject</th>
                <th className="table__heading">Issue Date</th>
                <th className="table__heading">Payment Term</th>
                <th className="table__heading">Period Start</th>
                <th className="table__heading">Period End</th>
                <th className="table__heading">Amount</th>
                <th className="table__heading">State</th>
                <th className="table__heading">Paid Date</th>
              </tr>
            </thead>
            <tbody>
              {this.project.invoices.map((invoice, key) => {
                const {
                  invoice_id,
                  subject,
                  issue_date,
                  payment_term,
                  period_start,
                  period_end,
                  amount,
                  state,
                  paid_date
                } = invoice;
                return (
                  <tr className="table__row" key={key}>
                    <td className="table__data">{invoice_id}</td>
                    <td className="table__data">{subject || `-`}</td>
                    <td className="table__data">{issue_date}</td>
                    <td className="table__data">{payment_term}</td>
                    <td className="table__data">{period_start}</td>
                    <td className="table__data">{period_end}</td>
                    <td className="table__data table__data--has-number">
                      <Currency number={amount} />
                    </td>
                    <td className="table__data">{state ? 'Active' : 'Inactive'}</td>
                    <td className="table__data">{paid_date}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Revenue />
        </main>
      </div>
    );
  }
}

export default Invoices;
