
import React, { Component } from "react";
import SwaggerEditor, { plugins } from "swagger-editor";
import "swagger-editor/dist/swagger-editor.css";

// Styles
import "./swagger.module.scss";

// API Docs
import doc from "./docs/openapi.yaml";

export class SwaggerDoc extends Component {

  componentDidMount() {
    window.editor = SwaggerEditor({
      dom_id: "#swagger-editor",
      layout: "EditorLayout",
      plugins: Object.values(plugins),
      url: doc
    });
  }
  render() {
    return <div id="swagger-editor"></div>;
  }
}

export default SwaggerDoc;
