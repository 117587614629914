// ==== Component ========================================================
// Filter
// =======================================================================

import React, { Component } from "react";

// Modules

// Styles
import styles from "./Filter.module.scss";

export class Filter extends Component {
  render() {
    return (
      <div className={styles.Filter}>
        <div className={styles.Label}>Search Projects between</div>
        <input
          className={styles.Input}
          type="date"
          name="startedAfter"
          id="startedAfter"
        />
        <div className={styles.Label}>to</div>
        <input
          className={styles.Input}
          type="date"
          name="endedBefore"
          id="endedBefore"
        />
        <button className={styles.Button}>Search</button>
      </div>
    );
  }
}

export default Filter;
