import React, { Component } from "react";

// Import React-Router-Dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Modules
import Login from "./Login/index";
import Dashboard from "./Dashboard/index";
import Projects from "./Projects/index";
import ProjectDetail from "./ProjectDetail/index";
import Invoices from "./Invoices/index";
import SwaggerDoc from "./Swagger/index";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route exact path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/projects">
          <Projects />
        </Route>
        <Route exact path="/projects/:id">
          <ProjectDetail />
        </Route>
        <Route exact path="/projects/:id/invoices">
          <Invoices />
        </Route>
        <Route exact path="/docs">
          <SwaggerDoc />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
