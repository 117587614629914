// ==== Component ========================================================
// Revenue
// =======================================================================

import React, { Component } from "react";

// Modules
import InputField from "../InputField/index";
import TextArea from "../TextArea/index";
import Button from "../Button/index";

// Styles
import styles from "./revenue.module.scss";

export class Revenue extends Component {
  render() {
    return (
      <>
        <h3 className={styles.Title}>
          Update Untracked Revenue{" "}
          <small>not been tracked in harvest invoices</small>
        </h3>
        <InputField
          label="Untracked Revenue Amount"
          type="number"
          name="untrackedRevenue"
          id="untrackedRevenue"
          placeholder=""
          inline={true}
        />
        <TextArea
          label="Invoicing Term"
          type="number"
          name="untrackedRevenue"
          id="untrackedRevenue"
          placeholder=""
          inline={true}
        />
        <Button label="Update" id="updateRevenueButton" type="button" />
      </>
    );
  }
}

export default Revenue;
