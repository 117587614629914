// ==== Helper Component =================================================
// Number Formats
// =======================================================================

import { PureComponent } from "react";

export class Currency extends PureComponent {
  render() {
    const { language, currency, number, minimumFractionDigits } = this.props;
    const output = new Intl.NumberFormat(language || `en-US`, {
      style: "currency",
      currency: currency || `USD`,
      minimumFractionDigits: minimumFractionDigits || 0
    }).format(number);
    return output;
  }
}

export class Number extends PureComponent {
  render() {
    const { language, number } = this.props;
    const output = new Intl.NumberFormat(language || "en-US").format(number);
    return output;
  }
}

export default { Currency, Number };
