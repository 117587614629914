// ==== Component ========================================================
// Login
// =======================================================================

import React, { Component } from "react";

// Modules
import Brand from "../Brand";
import InputField from "../InputField/index";
import Button from "../Button/index";

// Styles
import styles from "./Login.module.scss";

export class Login extends Component {
  render() {
    return (
      <div className={styles.Login}>
        <div className={styles.Container}>
          <form className={`form ${styles.Form}`}>
            <InputField
              label="User Name / Email Address"
              type="text"
              name="userName"
              id="userName"
              placeholder=""
              inline={false}
            />
            <InputField
              label="Password"
              type="password"
              name="password"
              id="password"
              placeholder=""
              inline={false}
            />
            <Button label="Login" id="updateRevenueButton" type="button" />
          </form>
        </div>
      </div>
    );
  }
}

export default Login;
