// ==== Component ========================================================
// Text Area
// =======================================================================

import React, { Component } from "react";

// styles
import styles from "./TextArea.module.scss";

export class TextArea extends Component {
  render() {
    const { label, name, id, placeholder, inline } = this.props;

    return (
      <div className={`${styles.Field} ${inline ? styles.FieldInline : ""}`}>
        <label className={styles.Label} htmlFor={id}>
          {label}
        </label>
        <textarea
          className={styles.Input}
          name={name}
          id={id}
          placeholder={placeholder}
        ></textarea>
      </div>
    );
  }
}

export default TextArea;
