// ==== Component ========================================================
// Project Details
// =======================================================================

import React, { Component } from "react";

// Modules
import Header from "../Header/index";
import Hero from "../Hero/index";

// Styles
import styles from "./ProjectDetail.module.scss";

export class ProjectDetail extends Component {
  project = {
    id: "864b3a51-1616-40a0-baf7-a683dba04935",
    name: "ABC Company",
    payment_terms: "",
    invoicing_terms: "",
    deposit_on_hand: 12234234.03,
    forecasted_work: 12234234.03,
    outstanding_invoice: 12234234.03,
    total_revenue_to_date: 12234234.03,
    billable_rate: 12234234.03,
    realized_hourly_rate: 12234234.03,
    hours_worked: 123,
    weeks_worked: 123,
    hours_resourced_to_date: 123,
    hours_delta: 123,
    hours_planned: 123,
    hours_remaining: 123,
    date_stared: "2019-11-13",
    date_end: "2019-11-13"
  };
  render() {
    return (
      <div className={`Page ${styles.ProjectDetail}`}>
        <Header />
        <Hero title={this.project.name} />
        <div className={styles.Project}>
          {Object.keys(this.project).map((keyName, keyIndex) => {
            return (
              <div className={styles.Item} key={keyIndex}>
                <div className={styles.Label}>{keyName.replace(/_/g, " ")}</div>
                <div className={styles.Info}>{this.project[keyName]}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ProjectDetail;
