// ==== Component ========================================================
// Dashboard
// =======================================================================

import React, { Component } from "react";

// Modules
import Header from "../Header/index";
import Hero from "../Hero/index";

// Styles
import styles from "./Dashboard.module.scss";

export class Dashboard extends Component {
  render() {
    return (
      <div className={`Page ${styles.Dashboard}`}>
        <Header />
        <Hero title="Dashboard" />
        <main className="main">Dashboard Content / Charts</main>
      </div>
    );
  }
}

export default Dashboard;
